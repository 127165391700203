.profile-link {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    text-decoration: none;
  
    .profile-image {
      width: 150px;
      height: 150px;
      border-radius: 50%;
      object-fit: cover;
      border: 2px solid var(--accent-color);
      transition: border-color 0.3s ease;
    }
  
    .account-name {
      font-size: 24px;
      font-family: generalSans-SemiBold;
      color: var(--text-color);
      text-align: center;
      margin: 0;
      transition: color 0.3s ease;
    }
  
    &:hover {
      cursor: pointer;
      background-color: rgba(var(--accent-color-rgb), 0.1);
      
      .account-name {
        color: var(--accent-color);
      }
      
      .profile-image {
        border-color: var(--accent-color);
      }
    }
  }
  
  .loading-spinner, .profile-error {
    padding: 20px;
    text-align: center;
    font-family: generalSans-Medium;
    color: var(--text-color);
  }
  
  @media (max-width: 500px) {
    .profile-link {
      padding: 10px;
  
      .profile-image {
        width: 100px;
        height: 100px;
      }
  
      .account-name {
        font-size: 18px;
      }
    }
    
    .loading-spinner, .profile-error {
      font-size: 14px;
      padding: 10px;
    }
  }