.profile-stat-box {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.stats-label {
  flex: 2;
  margin-right: 10px;
}

.streak-label {
  flex: 1;
  margin: 0 10px;
}

.badges-label {
  flex: 2;
  margin-left: 10px;
}

.stat-view, .badge-view {
  height: 140px;
  border-radius: 12px;
  background-color: var(--light-bounding-color);
  display: flex;
  justify-content: center;
  align-items: center;
}

.streak-view {
  height: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.streak-value-col {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 10px;

  .streak-number {
    font-size: 100px;
    font-family: generalSans-SemiBold;
    
  }
  
  .streak-sub-heading {
    font-size: 20px;
    font-family: generalSans-SemiBold;
    
  }
}



.stat-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 10px;
  width: 100%;
  height: 100%;

}

.stat-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.profile-stat-main {
  color: var(--accent-color);
  font-size: 24px;
  font-family: generalSans-SemiBold;
  margin: 0;
}

.profile-stat-sub-header {
  color: var(--text-color);
  font-size: 12px;
  font-family: generalSans-SemiBold;
  margin: 5px 0 0;
}
