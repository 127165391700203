.search {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  .search-content {
    display: flex;
    flex: 1;
    overflow-y:auto;    
    margin-top: 20px;
  }
  
  .search-filter {
    width: auto;
    overflow-y: auto;
    position: sticky;
    top:0px;
    padding-left:20px;
    box-sizing: border-box;
  }
  .search-header{
    margin-bottom:10px;
  }
  .search-results {
    width: 40vw;
    // overflow-y: auto;
    margin-left: 5vw;
  }
  
  .searched-line-spacer-temp {
    height: 12px;
    width: 100%;
  }
  
  .pinned-new-line-wrapper {
    height: 40px;
    width: 40px;
    background-color: var(--accent-color);
    color: var(--background-color);
    position: fixed;
    bottom: 20px;
    right: 20px;
    border-radius: 8px;
    text-align: center;
    align-content: center;
  
    font-size: 25px;
    font-family: "GeneralSans-Regular";
    transition: all 0.5s ease;
  }
  
  .pinned-new-line-wrapper:hover {
    background-color: var(--text-color);
    cursor: pointer;
  }
  .pagination{
    display: flex;
    justify-content: center;
    margin-top: 20px;
    align-items: center;
    gap:10px;
    padding-bottom:20px;
    button{
        background: var(--background-color);
        color: var(--text-color);
        display: flex;
        align-items: center;
        justify-content: center;
        padding:5px 0;
        font-size:16px;
        width:30px;
        height:30px;
        user-select: none;
        iconify-icon{
            font-size:16px;
            margin:10px;
        }
        &:hover{
            background-color: var(--text-color);
            color:var(--background-color)
        }
        &.active{
            background-color:var(--text-color);
            color: var(--background-color);
        }
    }
    .pages{
        display: flex;
        gap:2px;
    }
  }
}
