.line-previewer {
  width: 30vw;
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  max-height: 550px;
}

.line-previewer .heading-line {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.line-previewer .preview-title {
  margin-right: 10px;
  font-family: GeneralSans-Bold;
  font-size: 30px;
}

/* sizing should prob be temp */
.line-previewer .line-thumb-nail {
  width: 100%;
  height: 12vw;
  margin-bottom: 10px;
  border-radius: 12px;
}

/* I am being stupid and can't figure out how to get it to just truncate after a chunk and end with ...
somehow this isn't it, might be somthing that is easier to apply on the js side. TODO: Max Bio 500 words in preview */
.line-previewer .preview-bio {
  margin-bottom: 15px;
  font-family: GeneralSans-Regular;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  flex-grow:1;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size:16px;
  display: -webkit-box; /* Needed for ellipsis */
  -webkit-box-orient: vertical;
  //white shadow inset to make it look like it is fading out
  position: relative;
}

// I have no idea why i added this code but it makes a weired fadeout that shouldn't be there
// removing for now but gonna leave as a marker incase it was somthign actually important
// .preview-bio::after {
//   content: '';
//   position: absolute;
//   bottom: 0;
//   right: 0;
//   width: 100%;
//   height: 2em;
//   background: linear-gradient(to bottom, rgba(255, 255, 255, 0), white 50%);
// }

.line-previewer .line-participant-count {
  align-items: center;
  display: flex;
}

.line-owner-tag {
  font-family: GeneralSans-SemiBold;
  font-size: 15px;
  align-items: center;
  display: flex;
  transition: color 0.5s ease;
}

.line-owner-tag:hover {
  color: var(--accent-color);
}

.line-owner-pfp-container {
  width: 40px;
  height: 40px;
  border-radius: 12px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  flex-shrink: 0;
}

.line-owner-pfp-home {  
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 500px) {
    .line-previewer {
      width: 80vw;
      margin-left: 0;
      .line-thumb-nail{
        width:100%;
        height: 40vw;   
      }
      .preview-bio{
        width:100%;
      }
    }
    
}