.listed-line-wrapper {
    width: 100%;
    height: 160px;
    min-height:160px;
    display: flex;
    justify-content: space-between;
    gap:20px;
}

/* text and button container */
.text-and-button-wrapper {
    width: 70%;
    max-width:70%;
    overflow:hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

/* text */
.text-wrapper {
    display: flex;
    flex-direction: column;
}

.header-wrapper {
    text-wrap: nowrap;
    width: 100%;
    max-width:100%;
    display: flex;
    align-items: center;
    align-content: center;
}

.searched-line-name {
    color: var(--text-color);
    margin: 0;
    font-family: "GeneralSans-SemiBold";
    font-size: 25px;   
    margin-right: 10px;
    white-space: nowrap;
    overflow:hidden;
    text-overflow:ellipsis;
    flex:1;
    a{
        text-decoration: none;
        color: var(--text-color);
    }
}

.preview-bio {
    color: var(--text-color);
    font-family: "GeneralSans-Regular";
    font-size: 14px;
    width: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden;
}

/* buttons */
.button-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.searched-join-button,
.learn-more-button {
    height: 30px;
    background-color: var(--light-bounding-color);
    color: var(--text-color);
    border-radius: 8px;
    text-align: center;
    align-content: center;
    font-family: "GeneralSans-Medium";
    font-size: 15px;
    transition: all 0.3s ease;
    border: none;
}

.searched-join-button {
    flex-grow:1;
    margin-right: 10px;
}

.searched-join-button:hover {
    background-color: var(--accent-color);
    color: var(--light-bounding-color);
}

.learn-more-button {
    width:fit-content;
}

.learn-more-button:hover {
    background-color: var(--text-color);
    color: var(--light-bounding-color);
}

/* image */
.searched-line-cover {
    height: 100%;
    aspect-ratio: 1/1;
    border-radius: 8px;
    background-color: yellow;
}

.participants-wrapper {
    display: flex;
    align-items: center;
    color: var(--accent-color);
    opacity: 80%;
    font-family: "GeneralSans-Medium";
    font-size: 14px;
    margin-top: 5px;
}

.searched-line-participants-icon {
    fill: var(--accent-color);
    width: 20px;
    aspect-ratio: 1/1;
    margin-right: 2px;
}