.chrome-extension-custom-outlink {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    background-color: var(--light-bounding-color);
    font-size: 16px;
    font-weight: bold;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s ease;
    
    .text-styling {
        font-family: "GeneralSans-Medium";
        text-decoration: none;
        color: var(--text-color);
        transition: all 0.3s ease;
        
    }


    &:hover {

        .text-styling {
            color: var(--accent-color);
        }
    }
    .chrome-logo {
        width: 20px;
        height: 20px;
        margin-right: 10px;
    }
}