.pickett-list{
    width:100%;
    display:flex;
    flex-direction: column;
    align-items: center;
    gap:10px;
}

.list-items{
    display: flex;
    position:relative;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin-top: 15px;
    gap:12px;
    width:95%;
    transition: all 0.3s;
    overflow:hidden;
}

.pickett-list .gradient{
    width:100%;
    position:absolute;
    bottom:0;
    height: 22px;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
    transition: all 0.3s;
}

.pickett-list .gradient.invisible{
    opacity: 0;
    pointer-events: none;
}

.pickett-list button{
    padding:3px 15px;
    outline:none;
    border:none;
    border-radius: 5px;
    cursor:pointer;
    font-size:12px;
    background-color: var(--light-bounding-color);
    color: var(--text);
}

.pickett{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    text-decoration: none;
    color: var(--text-color);
    p{
        font-size:14px;
        font-family: GeneralSans-Medium;
        color: var(--text-color);
        margin:0;
    }
    .left{
        display: flex;
        align-items: center;
        gap:5px;
    }
}
