.footer {
    background-color: var(--text-color);
    color: var(--background-color);
    margin-top: 20px;
    border-radius: 16px 16px 0px 0px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .footer-content {
        display: flex;
        justify-content: space-between;
        width: 80%;

        .footer-section {
            h4 {
                font-size: 1.1rem;
                color: var(--background-color);
            }

            ul {
                list-style: none;
                padding: 0;

                li {
                    margin-bottom: 8px;

                    a {
                        color: var(--background-color);
                        text-decoration: none;
                        font-size: 0.9rem;
                        transition: color 0.3s ease;

                        &:hover {
                            color: var(--accent-color);
                        }
                    }
                }
            }
        }
    }

    .footer-bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 80%;
        border-top: 2px solid var(--background-color);
        padding-top: 10px;
        
        p {
        color: var(--accent-color);
        }
        .social-links {
            a {
                color: var(--background-color);
                margin-left: 15px;
                font-size: 1.2rem;
                transition: color 0.3s ease;

                &:hover {
                    color: var(--accent-color);
                }
            }
        }
    }

    @media (max-width: 768px) {
        .footer-content {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 20px;
            text-align: left;

            .footer-section {
                margin-bottom: 0;

                h4 {
                    font-size: 1rem;
                    color: var(--background-color);
                }

                ul {
                    li {
                        a {
                            font-size: 0.8rem;
                        }
                    }
                }
            }
        }

        .footer-bottom {
            flex-direction: column;
            text-align: center;

            p {
                margin-bottom: 10px;
                 }

            .social-links {
                display: flex;
                justify-content: center;
                a {
                    margin: 0 10px;
                }
            }
        }
    }
}