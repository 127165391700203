.create-line {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    min-height: 100vh;

    .content {
        width: 90%;
        max-width: 1200px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        gap: 10px;
        margin-top: 30px;
        
        > :first-child {
            flex: 0 0 480px;
            height: 300px;
            margin-left: 5vw;
            margin-right: -5vw;
            margin-top: 30px;
            position: sticky;
            top: 100px;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
            border-radius: 8px;
            overflow: hidden;
        }
        
        > :last-child {
            flex: 1;
            min-width: 0;
        }
    }
    
    .create-header {
        position: relative;
        padding: 20px 0;
        box-sizing: border-box;
        width: 100%;
        max-width: 600px;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        gap: 10px;
        h1 {
            font-size: 40px;
            margin: 0;
            margin-bottom: 3px;
        }
    }
}

@media screen and (max-width: 900px) {
    .create-line {
        .content {
            flex-direction: column;
            align-items: center;
            gap: 10px;
            margin-top: 0;
            
            > :first-child {
                position: relative;
                top: 0;
                width: 480px;
                height: 10vh;
                min-height: 70px;
                max-height: 120px;
                margin: 0;
            }
        }
    }
}

@media screen and (max-width: 500px) {
    .create-line {
        .content {
            width: 100%;
            padding: 0 20px;
            box-sizing: border-box;
            
            > :first-child {
                width: 100%;
                max-width: 320px;
                height: 15vh;
                min-height: 60px;
                margin: 0;
            }
        }
    }
}