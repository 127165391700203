/* Alignment: */
.nav-bar {
  display: flex;
  align-items: center;
  width: 100vw;
  height: 45px;
  background-color: transparent;
  -webkit-user-select:none;
  user-select: none;
  margin: 10px 0;  
  position: fixed;
  z-index: 100;
  top:0;
  left:0;
  padding: 0 20px;
  box-sizing: border-box;
}

.nav-bar-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 42px;
}

.nav-left,
.nav-right {
  flex: 1;
}

.nav-left {
  justify-content: flex-start;
  display: flex;
  align-items: center;
  align-content: center;
  flex-direction: row;
}

.nav-right {
  justify-content: flex-end;
  display: flex;
  align-items: center;
  
  .user-dropdown {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    
    .user-dropdown-header {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      padding: 5px 10px;
      border-radius: 13px;
      align-items: center;
      cursor: pointer;
      
      .pfp {
        width: 30px;
        height: 30px;
        border-radius: 10px;
        margin-right: 10px;
        overflow: clip;
        
        img {
          width: 110%;
          height: 110%;
          object-fit: fill;
        }
      }
      
      p {
        margin: 0;
        padding: 0;
      }
      
      &:hover {
        color: var(--accent-color);
      }
    }
    
    .dropdown-menu {
      position: absolute;
      top: calc(100% + 5px);
      right: 0;
      // width: 180px;
      // box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
      z-index: 101;
      overflow: hidden;
      
      .dropdown-item {
        display: flex;
        align-items: center;
        padding: 0px 28px;
        height: 36px;
        cursor: pointer;
        font-family: GeneralSans-Medium;
        color: var(--text-color);
        background-color: var(--light-bounding-color);
        margin-bottom: 5px;
        transition: color 0.2s ease;

      border-radius: 10px;
        
        .dropdown-icon {
          font-size: 20px;
          margin-right: 12px;
          color: inherit;
        }
        
        &:hover {
          color: var(--accent-color);
        }
        
        // &:not(:last-child) {
        //   border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        // }
      }
    }
  }
}

/* Contents: */

/* relic code from when logo was png, still needed for space but could be reworked
to simply use  */
.break {
  margin-right: 15px;
}

/* signup/login button */
/* i feel these buttons are not consitent enough, also currently hardcoding sizes and I think that works but possibly should swap to text that scales */
.login-button {
  /* Box Style Settings */
  border: var(--light-bounding-color) 2px solid;
  padding: 4px 20px 4px 20px;
  border-radius: 12px;
  margin-right: 15px;
  transition: color 0.4s ease, background-color 0.4s ease, border 0.4s ease;
  cursor: pointer;
  /* Text Settings */
  font-family: GeneralSans-Medium;
  font-size: 16px;
  color: var(--text-color);
  background-color: var(--light-bounding-color);
}

.login-button:hover {
  color: var(--background-color);
  background-color: var(--text-color);
  border: var(--text-color) 2px solid;
}

.join-button {
  /* Box Style Settings */
  border: var(--accent-color) 2px solid;
  padding: 4px 30px 4px 30px;
  border-radius: 12px;
  transition: color 0.4s ease, background-color 0.4s ease, border 0.4s ease;
  cursor: pointer;
  /* Text Settings */
  font-family: GeneralSans-Medium;
  font-size: 16px;
  color: var(--background-color);
  background-color: var(--accent-color);
  font-weight:500;
}

.join-button:hover {
  color: var(--background-color);
  background-color: var(--text-color);
  border: var(--text-color) 2px solid;
}

@media(max-width:500px){
    .nav-bar{
        margin:5px 0;
        padding: 0 15px;
        .login-button,
        .join-button{
            padding: 4px 10px 4px 10px;
            font-size:15px;
        }
    }
}