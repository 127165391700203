.line-banner-upload {
    margin-top: 25px;
}

// moving color option code here for now to compress them into 1 step
.color-options{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 30px 0px 32px 15px;
    width:100%;
    height:fit-content;
    .custom-palettes{
        display:flex;
        flex-direction: column;
        gap:8px;
    }

    .color-option {
        opacity: 0;
        animation: fadeInUp 0.8s ease forwards;
    }
}

@keyframes fadeInUp {
    0% {
      opacity: 0;
      transform: translateY(30px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  