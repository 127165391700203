.search-filter-selector-container {
    width: 350px;
    height: auto;
    align-items: left;
    justify-content: left;
    text-align: left;
    background-color: var(--background-color);

    .participant-threshold-input {
        background-color: var(--light-bounding-color);
        border: none;
        border-radius: 12px;
        height: 35px;
        color: var(--text-color);
        font-family: "GeneralSans-Medium";
        text-align: center;
        font-size: 15px;
        margin-left: 10px;
    }

    .participant-threshold-input:focus {
        outline: none;
    }

    .date-selector-container {
        display: flex;
        justify-content: space-between;
    }

    .search-year-range {
        width: calc(50% - 5px); /* Subtracting half of the margin */
        height: 35px;
        padding: 0px;
        background-color: var(--light-bounding-color);
        border-radius: 12px;
        border: 2px solid var(--light-bounding-color);
        text-align: center;
        color: var(--text-color);
        font-family: GeneralSans-Medium;
        font-size: 15px;
        transition: border 0.5s ease, color 0.5s ease;
    }

    .search-year-range:first-child {
        margin-right: 5px;
    }

    .search-year-range:last-child {
        margin-left: 5px;
    }

    .search-year-range:focus {
        outline: none;
        color: var(--accent-color);
        border: 2px solid var(--accent-color);
    }
}

/* filter animation */
.filter-item {
    opacity: 0;
    transform: translateY(-20px);
    max-height: 0;
    overflow: hidden;
    transition: opacity 0.3s ease, transform 0.3s ease, max-height 0.3s ease;
}

.filter-item.show {
    opacity: 1;
    transform: translateY(0);
    max-height: 500px; /* Adjust this value based on the maximum height of your filters */
}


.temp-spacer {
    margin-top: 10px;
}